html, body {
   max-width: 100%;
   overflow-x: hidden;
}

.no-padding {
    padding: 0px;
    margin: 0px;
}

#root {
    position: absolute;
    width: 100%;
}

.time {
  font-size: 32px;
}



